/* eslint-disable max-len */
import React from 'react'
import T from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Section from '../components/Section'
import ImageList from '../components/ImageList'
import Propaganda from '../components/Propaganda'
import RecipePreview from '../components/RecipePreview'
// Recipes video
import recipesPoster from '../assets/videoes/recipes.jpg'
import recipesWEBM from '../assets/videoes/recipes.webm'
import recipesMP4 from '../assets/videoes/recipes.mp4'
// Weekmenu video
import weekmenuPoster from '../assets/videoes/weekmenu.jpg'
import weekmenuWEBM from '../assets/videoes/weekmenu.webm'
import weekmenuMP4 from '../assets/videoes/weekmenu.mp4'

export default function Page({
  location,
  data: {
    food1,
    food2,
    food3,
    food4,
    food5,
    food6,
    food7,
    food8,
    food9,
    food10,
    food11,
    food12,
    products,
    recipe,
  },
}) {
  const DINNERS = [
    {
      id: 'biff',
      title: 'Vietnamesisk biff',
      ...food1,
    },
    {
      id: 'fiskegrateng',
      title: 'Klassisk fiskegrateng',
      ...food2,
    },
    {
      id: 'kjottsaus',
      title: 'Kjøttsaus med rotgrønnsaker',
      ...food3,
    },
    {
      id: 'carbonara',
      title: 'Pasta carbonara',
      ...food4,
    },
  ]

  const ACCESSORIES = [
    {
      id: 'gresskar',
      title: 'Bakt gresskarpuré',
      ...food5,
    },
    {
      id: 'salsa',
      title: 'Meksikansk tomatsalsa',
      ...food6,
    },
    {
      id: 'soyasmor',
      title: 'Soyasmør',
      ...food7,
    },
    {
      id: 'sandefjord',
      title: 'Sandefjordsaus',
      ...food8,
    },
  ]

  const BAKING = [
    {
      id: 'kanelknuter',
      title: 'Kanelknuter',
      ...food9,
    },
    {
      id: 'vannbakels',
      title: 'Fylte vannbakkels med crumble',
      ...food10,
    },
    {
      id: 'hveteboller',
      title: 'Luftige hveteboller',
      ...food11,
    },
    {
      id: 'matpakkebrod',
      title: 'Matpakkebrød',
      ...food12,
    },
  ]

  return (
    <>
      <SEO title="Oppskrifter med video, delt inn i enkle steg" />
      <Hero
        title="Stegvise oppskrifter med korte videoer, skreddersydd for mobilen"
        video={{
          poster: recipesPoster,
          webm: recipesWEBM,
          mp4: recipesMP4,
        }}
        action={{ label: 'Last ned appen', to: '/last-ned' }}
        type="small"
      />
      <Hero
        title="Oppdateres hver uke"
        preamble="Ukesmenyer med rask og sunn hverdagsmat, med en superfin handleliste til butikken. I tillegg kommer det helgekos og andre spennende oppskrifter hver uke."
        video={{
          poster: weekmenuPoster,
          webm: weekmenuWEBM,
          mp4: weekmenuMP4,
        }}
        type={['uppercase', 'flip']}
        section
      />

      {recipe.recipe && (
        <Section>
          <RecipePreview
            title="Prøv en av våre oppskrifter"
            preamble="En gatematfavoritt fra Singapore. Krabbeklør i sort pepper-saus. Denne blir vinneren på i et hvert skalldyrlag."
            recipe={recipe.recipe}
          />
        </Section>
      )}

      <Section title="Noen av oppskriftene i Foodsteps">
        <ImageList title="Middager" items={DINNERS} type="uppercase" />
        <ImageList title="Tilbehør" items={ACCESSORIES} type="uppercase" />
        <ImageList title="Baking" items={BAKING} type="uppercase" />
      </Section>

      <Propaganda products={products.products} />
    </>
  )
}

Page.propTypes = {
  location: T.object.isRequired,
  data: T.object.isRequired,
}

export const query = graphql`
  query {
    # DINNERS
    food1: file(relativePath: { eq: "oppskrifter/vietnamesisk-biff.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food2: file(relativePath: { eq: "oppskrifter/fiskegrateng.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food3: file(relativePath: { eq: "oppskrifter/kjottsaus.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food4: file(relativePath: { eq: "oppskrifter/carbonara.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # DINNERS
    food5: file(relativePath: { eq: "oppskrifter/gresskar.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food6: file(relativePath: { eq: "oppskrifter/salsa.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food7: file(relativePath: { eq: "oppskrifter/soyasmor.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food8: file(relativePath: { eq: "oppskrifter/sandefjord.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # BAKING
    food9: file(relativePath: { eq: "oppskrifter/kanelknuter.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food10: file(relativePath: { eq: "oppskrifter/vannbakels.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food11: file(relativePath: { eq: "oppskrifter/hveteboller.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food12: file(relativePath: { eq: "oppskrifter/matpakkebrod.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    products: foodsteps {
      products: products {
        id
        title
        description
        includes
        variants {
          promotion {
            price
            title
            id
            description
            eligible
          }
          price
          months
        }
      }
    }

    recipe: foodsteps {
      recipe(slug: "pepperkrabbe") {
        node {
          id
          slug
          title
          image {
            asset {
              metadata {
                palette {
                  darkMuted {
                    foreground
                    background
                  }
                  darkVibrant {
                    foreground
                    background
                  }
                  dominant {
                    foreground
                    background
                  }
                  lightMuted {
                    foreground
                    background
                  }
                  lightVibrant {
                    foreground
                    background
                  }
                  muted {
                    foreground
                    background
                  }
                  vibrant {
                    foreground
                    background
                  }
                }
              }
            }
            lqip
            url(width: 600)
          }
          duration
          preamble
          color
          quantitySettings {
            mode
            singleHeading
            doubleHeading
          }
          steps {
            id
            title
            video {
              asset {
                thumbnail
                url
              }
            }
            ingredients {
              id
              title
              quantity {
                two
                four
                six
                eight
              }
            }
          }
        }
      }
    }
  }
`
